import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import {
  filter,
  find,
  isEmpty,
  map,
  orderBy,
  random,
  round,
  sumBy,
} from "lodash";
import { toast } from "react-toastify";
import { bulkUpdateTiktokCampaign, getTiktokMetrics } from "../services/tiktok";
import { calculateTiktokMetrics } from "../utils";

moment.tz.setDefault("America/Los_Angeles");

const initialState = {
  isLoading: false,
  totalOrders: 0,
  list: [],
  summaryMetrics: {
    id: "summary-metrics",
    spend: 0,
    revenue: 0,
    orders: 0,
    clicks: 0,
    impressions: 0,
    adsRev: 500,
    roas: 0,
    cr: 0,
    cpc: 0,
  },
  campTypesInfo: [],
  startPoint: moment().format("YYYY-MM-DD"),
  endPoint: moment().format("YYYY-MM-DD"),
};

export const getTiktokMetricsAction = createAsyncThunk(
  "tiktok_metrics/get_all",
  async ({ start, end, level }) => getTiktokMetrics({ start, end, level })
);

export const bulkUpdateTiktokCampaignAction = createAsyncThunk(
  "tiktok_campaigns/bulk_update",
  async ({ payloads }) => bulkUpdateTiktokCampaign({ payloads })
);

const tiktokMetricsSlice = createSlice({
  name: "tiktok_metrics",
  initialState,
  reducers: {
    changeTiktokDate(state, action) {
      const [start, end] = action.payload;
      state.startPoint = moment(start).format("YYYY-MM-DD");
      state.endPoint = moment(end).format("YYYY-MM-DD");
    },
    updateTiktokSummaryMetrics(state, action) {
      const { payload } = action;

      const allData = map(payload, "model");
      const id =
        current(state).summaryMetrics[0]?.id ||
        `abdsjjs__2j34h-${random(1, 1000)}`;
      const totalOrders = sumBy(allData, "orders");
      const totalRevenue = sumBy(allData, "revenue");

      const totalSpend = sumBy(allData, "spend");
      const totalClicks = sumBy(allData, "clicks");
      const totalCR = !totalClicks ? 0 : (totalOrders / totalClicks) * 100;
      const totalCpc = !totalClicks ? 0 : totalSpend / totalClicks;
      const totalRoas = !totalSpend ? 0 : totalRevenue / totalSpend;
      const totalAdsRev = !totalRevenue ? 0 : (totalSpend / totalRevenue) * 100;
      const totalOrderTiktoks = sumBy(allData, "ordersTiktoks");
      const totalBudget = sumBy(
        filter(allData, (metric) => metric.status === "CAMPAIGN_STATUS_ENABLE"),
        "budget"
      );

      const newSummaryMetrics = {
        spend: totalSpend,
        orders: totalOrders,
        clicks: totalClicks,
        adsRev: totalAdsRev,
        roas: totalRoas,
        cr: totalCR,
        cpc: totalCpc,
        id,
        ordersTiktoks: totalOrderTiktoks,
        budget: totalBudget,
      };
      state.summaryMetrics = newSummaryMetrics;
    },
  },
  extraReducers: {
    [getTiktokMetricsAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getTiktokMetricsAction.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error("Get Tiktok Metrics failed");
    },
    [getTiktokMetricsAction.fulfilled]: (state, action) => {
      const { metrics, summaryMetrics, campTypesInfo, totalOrders } =
        action.payload;

      const newMetrics = calculateTiktokMetrics(
        filter(metrics, (i) => i.spend > 0 || i.orders > 0)
      );

      state.list = map(newMetrics, (i) => ({
        ...i,
        ytdAr: i.ytdAr * 100,
        ar: round(i.ar, 2) * 100,
        arTiktok: round(i.arTiktok, 2) * 100,
        diffAr: round(i.arTiktok, 2) * 100 - round(i.ar, 2) * 100,
        ...(i.revenue === 0 && {
          ar: 500,
          diffAr: round(i.arTiktok, 2) * 100 - 500,
        }),
      }));
      state.summaryMetrics = {
        ...summaryMetrics,
        id: "tiktok-summary-metrics",
      };
      state.campTypesInfo = orderBy(
        campTypesInfo,
        "campTypeTotalSpend",
        "desc"
      );
      state.totalOrders = totalOrders;
      state.isLoading = false;
      toast.success("Get Tiktok Metrics Successfully");
    },
    [bulkUpdateTiktokCampaignAction.pending]: (state, action) => {
      state.isLoading = true;
    },
    [bulkUpdateTiktokCampaignAction.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error("Update Tiktok Campaign Failed");
    },
    [bulkUpdateTiktokCampaignAction.fulfilled]: (state, action) => {
      const { payloads, data, success, message } = action.payload;
      const { list } = state;
      if (!success) {
        state.list = list;
        state.isLoading = false;
        toast.error(message);
      } else {
        if (isEmpty(data?.success)) {
          state.list = list;
          state.isLoading = false;
          toast.error(message);
          return;
        }

        const newList = map(list, (i) => {
          const newCampaign = find(data?.success, (p) => p === i.id);
          const targetCampaign = find(
            payloads,
            (p) => p.campaignId === newCampaign
          );
          if (isEmpty(newCampaign) || isEmpty(targetCampaign)) {
            return i;
          }
          const updatedField = {
            ...(targetCampaign?.status && {
              status: targetCampaign?.status,
            }),
            ...(targetCampaign?.budget && {
              budget: targetCampaign?.budget,
            }),
          };
          return {
            ...i,
            status: updatedField?.status || i.status,
            budget: updatedField?.budget || i.budget,
          };
        });
        state.list = newList;
        state.isLoading = false;
        toast.success("Update campaign successfully");
      }
    },
  },
});

export const { changeTiktokDate, updateTiktokSummaryMetrics } =
  tiktokMetricsSlice.actions;

export default tiktokMetricsSlice.reducer;
