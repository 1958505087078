import React from "react";
import {
  Box,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { isNil, toNumber } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { bulkUpdateTiktokCampaignAction } from "../../../../slices/tiktokMetrics.slicer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateCampModal = ({
  openModal,
  setOpenModal,
  onClickCancelIconHandler,
  row,
}) => {
  const dispatch = useDispatch();
  const {
    revenue,
    budget,
    cpp,
    orders,
    spend: cost,
    id: campaignId,
    campaignName,
    adsRev,
    cr,
    targetRoas,
  } = row;
  const [updatedBudget, setUpdatedBudget] = React.useState(budget);
  const [updatedTargetRoas, setUpdatedTargetRoas] = React.useState(
    targetRoas * 100
  );
  const { startPoint, endPoint } = useSelector((state) => state.tiktokMetrics);
  const onConfirmHandler = () => {
    if (isNil(updatedBudget) || updatedBudget === budget) {
      toast.error("Please update the budget");
      return;
    }

    dispatch(
      bulkUpdateTiktokCampaignAction({
        payloads: [
          {
            campaignId,
            budget: toNumber(updatedBudget),
          },
        ],
      })
    );
    setOpenModal(false);
  };
  return (
    <Modal
      open={openModal}
      onBackdropClick={() => setOpenModal(false)}
      onClose={onClickCancelIconHandler}
    >
      <Box sx={style}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Field</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: "bolder" }}>
                  Campaign Name
                </TableCell>
                <TableCell>{campaignName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bolder" }}>Budget</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    defaultValue={budget}
                    value={updatedBudget}
                    onChange={(e) => setUpdatedBudget(e.target.value)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bolder" }}>Spend</TableCell>
                <TableCell>{cost}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bolder" }}>Orders</TableCell>
                <TableCell>{orders}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bolder" }}>Ads/Rev</TableCell>
                <TableCell>
                  {revenue ? `${((cost / revenue) * 100)?.toFixed(0)}%` : "0%"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bolder" }}>CR</TableCell>
                <TableCell>{`${cr?.toFixed(2)}%`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: "bolder" }}>CPP</TableCell>
                <TableCell>{cpp}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="container">
          <Button
            onClick={onClickCancelIconHandler}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onConfirmHandler}
            color="success"
            disabled={isNil(updatedBudget) || updatedBudget === budget}
          >
            Confirm
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

UpdateCampModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onClickCancelIconHandler: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
};

export default UpdateCampModal;
