import http from "./index";

const getTiktokMetrics = async ({ start, end }) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const result = await http.get(
    `/tiktok-campaigns?start=${start}&end=${end}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result?.data?.data;
};

const bulkUpdateTiktokCampaign = async ({ payloads }) => {
  const token = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  const result = await http.put(
    `/tiktok-campaigns`,
    { payloads },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const response = result?.data;
  return {
    payloads,
    data: response?.success ? response?.data : null,
    message: response?.success ? response?.message : response?.error,
    success: response?.success,
  };
};

export { getTiktokMetrics, bulkUpdateTiktokCampaign };
